import axios from "axios";
import { config } from "../consts/api";

const uploadImageToServer = async (file, suitId, appApiKey) => {
  const formData = new FormData();
  let fieldName = "";

  if (file.type.startsWith("image/")) {
    fieldName = "photo";
  } else if (file.type === "application/pdf") {
    fieldName = "pdf";
  } else {
    throw new Error("Unsupported file type. Only images and PDFs are allowed.");
  }

  formData.append(fieldName, file);

  const response = await axios.post(
    `${config.API_HOST}/${suitId}/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        appApiKey,
      },
    }
  );

  const url = `https://${response.data}`;
  return url;
};

export { uploadImageToServer };
