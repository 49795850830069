import React from "react";
import classes from "./LoadingCard.scss";

export const LoadingCard = () => {
  return (
    <div className={classes.loadingCard}>
      <div className={classes.header}>
        <span></span>
      </div>

      <div className={classes.body}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div className={classes.footer}>
        <span></span>
      </div>
    </div>
  );
};

export default LoadingCard;
