import { Layout as AntdLayout, Avatar, Dropdown, Menu } from "antd";
import "antd/dist/antd.css";
import { getSuitBySubdomain, isLoggedIn, isSubUser } from "modules/user";
import PropTypes from "prop-types";
import React from "react";
import Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router";
import {
  generateAPIKey,
  getCurrentSuit,
  handleLogout,
  removeCurrentSuit,
  resetErrors,
  setActiveSuitId,
} from "reducers/user";
import SecondaryButton from "../../components/Form/Buttons/SecondaryButton/SecondaryButton.js";
import HeadwayWidget from "../../components/Headway/HeadwayWidget.js";
import classes from "./DefaultLayout.module.scss";
import Footer from "./Footer.js";
import Sidebar from "./Sidebar.js";
// import ProgressBar from "./components/ProgressBar.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsAppButton from "../../components/WhatsAppButton/index.js";
import { Toast } from "../../modules/toast.js";
import RetailGainOnBoarding from "../../routes/App/RetailGainOnBoarding/RetailGainOnBoarding.js";
import appgainLogo from "../../static/logos/appgain-logo-high-quality.svg";
const { Header, Content } = AntdLayout;

class DefaultLayout extends React.Component {
  static contextTypes = {
    store: PropTypes.object,
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      getCurrentSuit,
      setActiveSuitId,
      params: { subdomain },
    } = this.props;

    if (subdomain) {
      let currentSuit = getSuitBySubdomain(subdomain);
      getCurrentSuit(currentSuit.id);
      setActiveSuitId(currentSuit.id);
    }

    this.state = {
      collapsed: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (prevProps.error !== error) {
      this.handleError(error);
    }
  }

  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;

    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };

    generateAPIKey(data, suit.id).then(() => {
      getCurrentSuit(activeSuitId);
    });
  }

  handleError(error) {
    const { resetErrors } = this.props;
    if (error?.response?.body?.message) {
      Toast.error(error.response.body.message);
      resetErrors();
    }
  }

  handleLogout = () => {
    this.context.router.push("/auth/login");
    this.props.handleLogout();
    this.props.removeCurrentSuit();

    const itemsToRemove = [
      "schema",
      "user.isLoggedIn",
      "user.data",
      "user.suits",
    ];

    itemsToRemove.forEach((item) => {
      localStorage.removeItem(item);
    });
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  render() {
    const {
      user: { data },
      params: { subdomain },
      notifications,
    } = this.props;

    const suit = getSuitBySubdomain(subdomain);

    const { router } = this.context;
    const projectName = suit?.name;
    const shouldRenderRemainingSubscriptionDays =
      suit?.subscription.status.toLowerCase() === "trialing" && subdomain;
    const shouldRenderUpgradeButton = suit?.upgradable && subdomain;
    const isEnterprise = data?.user_type === "enterprise";

    const pathname = window.location.pathname;
    const shouldRenderLayout = !pathname.includes("welcome");
    const shouldRenderLogoInHeader = !subdomain && pathname.includes("user");

    return (
      <AntdLayout style={{ overflowX: "hidden" }}>
        {shouldRenderLayout && subdomain && (
          <Sidebar
            subdomain={subdomain}
            router={router}
            projectName={projectName}
            onCollapse={this.onCollapse}
            collapsed={this.state.collapsed}
          />
        )}
        <WhatsAppButton />
        <AntdLayout
          style={{
            marginLeft:
              subdomain && shouldRenderLayout
                ? this.state.collapsed
                  ? "80px"
                  : "250px"
                : 0,
            transition: "all 0.2s",
          }}
        >
          {isLoggedIn() && shouldRenderLayout && (
            <Header
              style={{
                background: "#fff",
                padding: "14px 24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "24px",
                height: "65px",
              }}
            >
              {shouldRenderLogoInHeader && (
                <img
                  src={appgainLogo}
                  alt="Appgain"
                  style={{
                    height: "40px",
                    marginRight: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => router.push("/home")}
                />
              )}

              {shouldRenderRemainingSubscriptionDays && (
                <span>
                  {I18n.t("nav_trial")}
                  {suit?.subscription_remaining_days}
                </span>
              )}

              {shouldRenderUpgradeButton && (
                <SecondaryButton
                  value={I18n.t("upgrade")}
                  onClick={() => {
                    router.push(`/app/${subdomain}/upgrade-plan`);
                  }}
                />
              )}

              <HeadwayWidget />

              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="0">
                      <Link to={"/user/profile"}>{I18n.t("nav_Profile")}</Link>
                    </Menu.Item>
                    {!isSubUser() && (
                      <Menu.Item key="1">
                        <Link to={"/user/sub-users"}>
                          {I18n.t("SubuserManagement")}
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item key="2">
                      <Link to={"/user/authentication"}>
                        {I18n.t("nav_Authentication")}
                      </Link>
                    </Menu.Item>
                    {!isEnterprise && (
                      <Menu.Item key="3">
                        <Link to={"/user/paymentmethods"}>
                          {I18n.t("PaymentMethods")}
                        </Link>
                      </Menu.Item>
                    )}
                    {!isEnterprise && (
                      <Menu.Item key="4">
                        <Link to={"/user/invoices"}>{I18n.t("Invoices")}</Link>
                      </Menu.Item>
                    )}
                    <Menu.Divider />
                    <Menu.Item key="5" onClick={this.handleLogout}>
                      {I18n.t("nav_LogOut")}
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {data?.name}
                  <Avatar>{data?.name?.[0]?.toUpperCase()}</Avatar>
                </span>
              </Dropdown>
            </Header>
          )}
          <Content className={classes.content}>
            {this.props.children}
            <Notifications notifications={notifications} />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
              theme="light"
            />
          </Content>
          {subdomain && <RetailGainOnBoarding />}
          {subdomain && shouldRenderLayout && <Footer />}
        </AntdLayout>
      </AntdLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications,
  user: state.user,
  error: [
    "user",
    "smartlink",
    "MicroPages",
    "template",
    "select",
    "automator",
    "config",
    "parse",
    "push",
    "deeplink",
    "events",
    "WhatsApp",
  ]
    .map((key) => state[key].error)
    .find((error) => error),
});

const mapActionCreators = {
  setActiveSuitId,
  handleLogout,
  resetErrors,
  removeCurrentSuit,
  generateAPIKey,
  getCurrentSuit,
};

export default connect(mapStateToProps, mapActionCreators)(DefaultLayout);

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  setActiveSuitId: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  resetErrors: PropTypes.func.isRequired,
  removeCurrentSuit: PropTypes.func.isRequired,
  generateAPIKey: PropTypes.func.isRequired,
  getCurrentSuit: PropTypes.func.isRequired,
  error: PropTypes.object,
};
