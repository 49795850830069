import Push from "api/Push";
import { CURRENCY, DATE_TYPE, NUMBER, STRING } from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import { SET_ACTIVE_SUIT_ID } from "./user";

// ------------------------------------
// Constants
// ------------------------------------

const GET_WEBPUSH_ANALYTICS = "GET_WEBPUSH_ANALYTICS";
const GET_SUIT_APPPUSHS_SEARCH = " GET_SUIT_APPPUSHS_SEARCH";
const GET_SUIT_WEBPUSHS_SEARCH = "GET_SUIT_WEBPUSHS_SEARCH";
const GET_SUIT_SMS = "GET_SUIT_SMS";
const GET_SUIT_SMS_SEARCH = "GET_SUIT_SMS_SEARCH";
const CREATE_SMS = "CREATE_SMS";
const GET_SUIT_EMAIL = "GET_SUIT_EMAIL";
const GET_SUIT_EMAIL_SEARCH = "GET_SUIT_EMAIL_SEARCH";
const GET_CAMPAIGN_ANALYTICS = "GET_CAMPAIGN_ANALYTICS";
const GET_SUIT_PUSH_STATISTICS = "GET_SUIT_PUSH_STATISTICS";
const DeleteCAMPAINAN = "DeleteCAMPAINAN";
const DUPLICATE_CAMPAIGN = "DUPLICATE_CAMPAIGN";
const REMOVEANALITICS = "REMOVEANALITICS";
const DOWNLOAD_TARGETLIST = "DOWNLOAD_TARGETLIST";
const CREATETARGETLIST = "CREATETARGETLIST";
const GET_SUIT_TARGETLIST = "GET_SUIT_TARGETLIST";
const DeleteTARGETLIST = "DeleteTARGETLIST";
const CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW =
  "CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW";
const SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU =
  "SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU =
  "CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU";
const CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS";
const Push_CHECK_DATATABLE_ROW = "Push_CHECK_DATATABLE_ROW";
const SORT_Push = "SORT_Push";
const Push_TOGGLE_ROW_ACTION_MENU = "Push_TOGGLE_ROW_ACTION_MENU";
const Push_CLEAR_ACTION_MENU = "Push_CLEAR_ACTION_MENU";
const Push_COLUMN_TOGGLE = "Push_COLUMN_TOGGLE";
const Push_RESET_COLUMNS = "Push_RESET_COLUMNS";
const CLEAR_CAMPAIGN_ANALYTICS = "CLEAR_CAMPAIGN_ANALYTICS";
const Push_CHECK_ALL_TABLE_ROWS = "Push_CHECK_ALL_TABLE_ROWS";
const DELETE_TARGET_LIST_BULK = "DELETE_TARGET_LIST_BULK";
const DELETE_CAMPAIGN_BULK = "DELETE_CAMPAIGN_BULK";

// ------------------------------------
// Actions
// ------------------------------------
export function checkTableRow(idx) {
  return {
    type: Push_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function clearCampaignAnalytics() {
  return {
    type: CLEAR_CAMPAIGN_ANALYTICS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_Push,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};
export function onColumnsToggle(idx) {
  return {
    type: Push_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetsColumns() {
  return {
    type: Push_RESET_COLUMNS,
  };
}
export function toggleRowMenu(idx) {
  return {
    type: Push_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}
export function clearAction(idx) {
  return {
    type: Push_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function checkAllRows(newChecked) {
  return {
    type: Push_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

// SMS
export function getSuitSMS(suitId, data) {
  return {
    type: GET_SUIT_SMS,
    payload: Push.getSuitSMS(suitId, data),
  };
}
//Email
export function getSuitEmail(suitId, apikey, data) {
  return {
    type: GET_SUIT_EMAIL,
    payload: Push.getSuitEmail(suitId, "email", data),
  };
}
// search
export function getSuitWebPushsSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_WEBPUSHS_SEARCH,
    payload: Push.getSuitPushsSearch(suitId, nameVal, "getSuitWebPushs"),
  };
}
// search
export function getSuitAppPushsSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_APPPUSHS_SEARCH,
    payload: Push.getSuitPushsSearch(suitId, nameVal, "appPush"),
  };
}

// search
export function getSuitSMSSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_SMS_SEARCH,
    payload: Push.getSuitPushsSearch(suitId, nameVal, "SMS"),
  };
}
export function CreateSMS(suitId) {
  return {
    type: CREATE_SMS,
    payload: Push.getSuitPushs(suitId, "SMS"),
  };
}

// search
export function getSuitEmailSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_EMAIL_SEARCH,
    payload: Push.getSuitPushsSearch(suitId, nameVal, "email"),
  };
}

export function getWebPushAnalytics(suitId, data) {
  return {
    type: GET_WEBPUSH_ANALYTICS,
    payload: Push.getWebPushAnalytics(suitId, data),
  };
}
export function getCampaignAnalytics(suitId, campaignId, data) {
  return {
    type: GET_CAMPAIGN_ANALYTICS,
    payload: Push.getCampaignAnalytics(suitId, campaignId, data),
  };
}
export function getSuitPushStatistics(suitId, data) {
  return {
    type: GET_SUIT_PUSH_STATISTICS,
    payload: Push.getSuitPushStatistics(suitId, data),
  };
}

export function deleteCampaign(suitId, campaignId) {
  return {
    type: DeleteCAMPAINAN,
    payload: Push.deleteCampain(suitId, campaignId),
  };
}

// campaignIds = {"campaign_ids": ["test", "test"]}
export function deleteCampaignBulk(suitId, campaignIds) {
  return {
    type: DELETE_CAMPAIGN_BULK,
    payload: Push.deleteCampaignBulk(suitId, campaignIds),
  };
}

export const duplicateCampaign = (campaign) => async (dispatch) => {
  return dispatch({
    type: DUPLICATE_CAMPAIGN,
    payload: campaign,
  });
};
export function removeCampaignAnalytics() {
  return {
    type: REMOVEANALITICS,
  };
}
export function createTargetList(suitId, data) {
  return {
    type: CREATETARGETLIST,
    payload: Push.createTargetList(suitId, data),
  };
}

export function listTargetList(suitId) {
  return {
    type: GET_SUIT_TARGETLIST,
    payload: Push.listTargetList(suitId),
  };
}
export function deleteTargetList(appId, targetListName) {
  return {
    type: DeleteTARGETLIST,
    payload: Push.deleteTargetList(appId, targetListName),
  };
}
export function deleteTargetListBulk(appId, targetListNames) {
  return {
    type: DELETE_TARGET_LIST_BULK,
    payload: Push.deleteTargetListBulk(appId, targetListNames),
  };
}
export function downloadTargetList(appId, targetListName) {
  return {
    type: DOWNLOAD_TARGETLIST,
    payload: Push.downloadTargetList(appId, targetListName),
  };
}

export function toggleRowActionMenu(index) {
  return {
    type: SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  webPushAnalytics: null,
  campaignAnalytics: null,
  summary: null,
  webPush: [],
  smsPushs: [],
  targetLists: null,
  targetListsObjects: null,
  Email: [],
  webPushs: [],
  isPending: false,
  duplicate: null,
  error: false,
  pushs: null,
  allPushs: [],
  count: null,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function pushReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_SUIT_WEBPUSHS_SEARCH}_PENDING`:
    case `${GET_SUIT_APPPUSHS_SEARCH}_PENDING`:
    case `${GET_SUIT_SMS}_PENDING`:
    case `${GET_SUIT_SMS_SEARCH}_PENDING`:
    case `${GET_SUIT_EMAIL}_PENDING`:
    case `${GET_SUIT_EMAIL_SEARCH}_PENDING`:
    case `${GET_WEBPUSH_ANALYTICS}_PENDING`:
    case `${DeleteCAMPAINAN}_PENDING`:
    case `${DELETE_CAMPAIGN_BULK}_PENDING`:
    case `${GET_SUIT_PUSH_STATISTICS}_PENDING`:
    case `${GET_CAMPAIGN_ANALYTICS}_PENDING`:
    case `${CREATETARGETLIST}_PENDING`:
    case `${GET_SUIT_TARGETLIST}_PENDING`:
    case `${DeleteTARGETLIST}_PENDING`:
    case `${DUPLICATE_CAMPAIGN}_PENDING`:
    case `${SORT_Push}_PENDING`:
      return { ...state, isPending: true, error: false };

    case `${GET_WEBPUSH_ANALYTICS}_REJECTED`:
    case `${DUPLICATE_CAMPAIGN}_REJECTED`:
    case `${GET_SUIT_WEBPUSHS_SEARCH}_REJECTED`:
    case `${GET_SUIT_APPPUSHS_SEARCH}_REJECTED`:
    case `${GET_SUIT_SMS}_REJECTED`:
    case `${GET_SUIT_SMS_SEARCH}_REJECTED`:
    case `${GET_SUIT_EMAIL}_REJECTED`:
    case `${GET_SUIT_EMAIL_SEARCH}_REJECTED`:
    case `${DeleteCAMPAINAN}_REJECTED`:
    case `${DELETE_CAMPAIGN_BULK}_REJECTED`:
    case `${GET_SUIT_PUSH_STATISTICS}_REJECTED`:
    case `${GET_SUIT_TARGETLIST}_REJECTED`:
    case `${DeleteTARGETLIST}_REJECTED`:
      return { ...state, isPending: false, error: action.payload };

    case `${GET_CAMPAIGN_ANALYTICS}_REJECTED`:
      return { ...state, isPending: false, error: true };

    case `${GET_WEBPUSH_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        webPushAnalytics: action.payload.body,
        isPending: false,
      };

    //searchsummary
    case `${GET_SUIT_WEBPUSHS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        webPushs: action.payload.body.campaigns,
      };

    case `${GET_SUIT_APPPUSHS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        appPushs: action.payload.body.campaigns,
      };
    case `${GET_SUIT_SMS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smsPushs: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        allPushs: action.payload.body.campaigns,

        duplicate: null,
      };
    // search
    case `${GET_SUIT_SMS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smsPushs: action.payload.body.campaigns,
      };
    case `${GET_SUIT_EMAIL}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Email: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        allPushs: action.payload.body.campaigns,
        duplicate: null,
      };
    // search
    case `${GET_SUIT_EMAIL_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Email: action.payload.body.campaigns,
      };

    case `${GET_SUIT_PUSH_STATISTICS}_FULFILLED`:
      return { ...state, isPending: false, summary: action.payload.body };
    case `${GET_CAMPAIGN_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        campaignAnalytics: action.payload.body,
        isPending: false,
      };
    case `${DeleteCAMPAINAN}_FULFILLED`:
    case `${DELETE_CAMPAIGN_BULK}_FULFILLED`:
    case `${CREATETARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${GET_SUIT_TARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        targetLists: prepareTableDate(action.payload.body.targetLists),
        targetListsObjects: action.payload.body.targetLists,
      };

    case `${DeleteTARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case CLEAR_CAMPAIGN_ANALYTICS:
      return {
        ...initialState,
      };

    case DUPLICATE_CAMPAIGN: {
      return {
        ...state,
        isPending: false,
        duplicate: action.payload,
      };
    }

    // Push Data Table Functions
    case Push_CHECK_DATATABLE_ROW:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.pushs.rows[rowIdx],
                  checked: !state.pushs.rows[rowIdx].checked,
                }
              : state.pushs.rows[rowIdx];
          }),
        },
      };
    case Push_RESET_COLUMNS:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          labels: state.pushs.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case Push_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.pushs.rows[rowIdx],
                  actionsMenu: !state.pushs.rows[rowIdx].actionsMenu,
                }
              : state.pushs.rows[rowIdx];
          }),
        },
      };

    case Push_COLUMN_TOGGLE:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          labels: state.pushs.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case Push_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case SORT_Push: {
      const { labels, rows } = state.pushs;
      const { type, payload } = action;
      const { name, descending } = payload;

      const indexOfKey = labels.findIndex((label) => label.name === name);

      let tableRows = rows.slice();

      switch (type) {
        case NUMBER:
          tableRows.sort((a, b) => a.values[indexOfKey] - b.values[indexOfKey]);
          break;
        case STRING:
          tableRows.sort((a, b) =>
            a.values[indexOfKey].localeCompare(b.values[indexOfKey])
          );
          break;
        case CURRENCY:
          tableRows.sort((a, b) => {
            const aCurrencyValue = parseFloat(
              a.values[indexOfKey].split(" ")[0]
            );
            const bCurrencyValue = parseFloat(
              b.values[indexOfKey].split(" ")[0]
            );
            return aCurrencyValue - bCurrencyValue;
          });
          break;
        case DATE_TYPE:
          tableRows.sort((a, b) => {
            const aDate = new Date(a.values[indexOfKey]);
            const bDate = new Date(b.values[indexOfKey]);
            return aDate.getTime() - bDate.getTime();
          });
          break;
      }

      if (descending) {
        tableRows.reverse();
      }

      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: tableRows,
        },
      };
    }
    case Push_CLEAR_ACTION_MENU:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case REMOVEANALITICS:
      return {
        ...state,
        campaignAnalytics: null,
        webPushAnalytics: null,
      };
    case SET_ACTIVE_SUIT_ID:
      return { ...state, summary: null };

    case SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.targetLists.rows[rowIdx],
                  actionsMenu: !state.targetLists.rows[rowIdx].actionsMenu,
                }
              : state.targetLists.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.targetLists.rows[rowIdx],
                  checked: !state.targetLists.rows[rowIdx].checked,
                }
              : state.targetLists.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    default:
      return state;
  }
}

function prepareTableDate(tableData) {
  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["_id", "target_list_name"],
      defaultValue: "N/A",
    },
    {
      name: "Count",
      has_sort: false,
      type: NUMBER,
      selected: true,
      varName: ["count"],
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    name: _.get(row, labels[0].varName, labels[0].defaultValue),
    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
    ],
  }));

  return { labels, rows, actions: true };
}

function preparePush(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Campaign Name",
      has_sort: false,
      type: "STRING",
      selected: true,
      varName: ["name"],
      defaultValue: "",
    },
    {
      name: "Title",
      has_sort: false,
      type: "STRING",
      selected: false,
      varName: ["title"],
      defaultValue: "NA",
    },
    {
      name: "Conversion",
      has_sort: true,
      type: "NUMBER",
      selected: false,
      varName: ["total_conversion_value"],
      defaultValue: 0,
    },
    {
      name: "Type",
      has_sort: false,
      type: "STRING",
      selected: true,
      varName: ["sending_type"],
      defaultValue: 0,
    },
    {
      name: "Success Ratio",
      has_sort: false,
      type: "STRING",
      selected: true,
      varName: ["sending_type"],
    },
    {
      name: "Target",
      has_sort: false,
      type: "STRING",
      selected: true,
    },
    {
      name: "Created At",
      has_sort: true,
      type: "DATE_TYPE",
      selected: true,
      varName: ["created_at"],
    },
    {
      name: "Sent on",
      has_sort: true,
      type: "DATE_TYPE",
      selected: true,
      hasTooltip: true,
      tooltipBehind: "How the users selected",
      varName: "occurrences[0].sent_at",
    },
    {
      name: "Scheduled At",
      has_sort: true,
      type: "DATE_TYPE",
      selected: true,
      hasTooltip: true,
      tooltipBehind: "How the users selected",
      varName: "occurrences[0].scheduled_at",
    },
    {
      name: "Message",
      has_sort: false,
      type: "STRING",
      selected: false,
      varName: ["message"],
    },
    {
      name: "URL",
      has_sort: false,
      type: "STRING",
      selected: false,
      varName: ["url"],
    },
    {
      name: "Sent",
      has_sort: true,
      type: "NUMBER",
      selected: false,
      varName: ["sent"],
    },
    {
      name: "Failed",
      has_sort: true,
      type: "STRING",
      selected: false,
      varName: ["failed"],
    },
  ];

  const rows = tableData.map((row) => {
    const sentData = row.sent_data || {};
    const sent = sentData.sent || 0;
    const failed = sentData.failed || 0;

    const successRatio =
      sent === 0 && failed === 0
        ? "0%"
        : ((sent / (failed + sent)) * 100).toFixed(1) + "%";

    let sentAt = "Scheduled";
    if (row.occurrences && row.occurrences[0] && row.occurrences[0].sent_at) {
      sentAt = moment
        .utc(row.occurrences[0].sent_at)
        .local()
        .format(dateFormat);
    }

    let scheduledAt = "NA";
    if (
      row.occurrences &&
      row.occurrences[0] &&
      row.occurrences[0].scheduled_at
    ) {
      scheduledAt = moment
        .utc(row.occurrences[0].scheduled_at)
        .local()
        .format(dateFormat);
    }

    const target = row?.target?.target_users;

    return {
      checked: false,
      actionsMenu: false,
      campaignID: row.campaign_id,
      suitID: row.suit_id,
      values: [
        row[labels[0].varName[0]] || labels[0].defaultValue, // name
        row[labels[1].varName[0]] || labels[1].defaultValue || "NA", // title
        row[labels[2].varName[0]] || labels[2].defaultValue || 0, // conversion
        row[labels[3].varName[0]], // type
        successRatio, // success ratio
        target,
        moment.utc(row.created_at).local().format(dateFormat),
        sentAt,
        scheduledAt,
        row[labels[8].varName[0]],
        row[labels[9].varName[0]],
        row[labels[10].varName[0]],
        row[labels[11].varName[0]],
      ],
    };
  });

  return { labels, rows, actions: [{ type: "delete" }] };
}
