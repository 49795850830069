/**
 * Finds the active sidebar menu item based on the given URL.
 *
 * @param {Array} items - The array of sidebar menu items to search through.
 * @param {string} url - The URL to match against the menu item links.
 * @returns {object} - The active menu item object if found, otherwise undefined.
 */
const activeSidebarMenuItem = (items, url) => {
  for (const item of items) {
    if (item.children) {
      for (const child of item.children) {
        if (child.children) {
          for (const grandChild of child.children) {
            if (grandChild.link === url || (grandChild.externalLink && grandChild.url === url)) {
              return grandChild;
            }
          }
        } else {
          if (child.link === url || (child.externalLink && child.url === url)) {
            return child;
          }
        }
      }
    } else {
      if (item.link === url || (item.externalLink && item.url === url)) {
        return item;
      }
    }
  }
};

/**
 * Returns an array of keys associated with the given URL.
 *
 * @param {Array} items - The array of items to search through.
 * @param {string} url - The URL to match against.
 * @return {Array} - An array of keys associated with the given URL.
 */
function selectedKeys(items, url) {
  const keys = [];
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.children) {
      for (let j = 0; j < item.children.length; j++) {
        const child = item.children[j];
        if (child.children) {
          for (let k = 0; k < child.children.length; k++) {
            const grandChild = child.children[k];
            if (grandChild.link === url || (grandChild.externalLink && grandChild.url === url)) {
              keys.push(grandChild.key);
              keys.push(child.key);
              keys.push(item.key);
            }
          }
        } else {
          if (child.link === url || (child.externalLink && child.url === url)) {
            keys.push(child.key);
            keys.push(item.key);
          }
        }
      }
    } else {
      if (item.link === url || (item.externalLink && item.url === url)) {
        keys.push(item.key);
      }
    }
  }
  return keys;
}

/**
 * Finds an item with the given key in the provided array of items.
 *
 * @param {string} key - The key to search for.
 * @param {Array} items - The array of items to search in.
 * @returns {Object|undefined} - The item with the given key, or undefined if not found.
 */
const findItemWithKey = (key, items) => {
  for (const item of items) {
    if (item.key === key) {
      return item;
    }
    if (item.children) {
      const child = findItemWithKey(key, item.children);
      if (child) {
        return child;
      }
    }
  }
};

export { activeSidebarMenuItem, findItemWithKey, selectedKeys };
