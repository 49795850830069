import { Col, Form, Row } from "components";
import TextBox from "components/Form/TextBox/TextBox";
import $ from "jquery";
import { maxLength, required, isValidNotEmptySpace } from "modules/validations";
import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Field, change, reduxForm } from "redux-form";
import silentPushSrc from "../../../../../static/rich-push/silent-push.png";
import textGifSrc from "../../../../../static/rich-push/text-gif.png";
import textHTMLWebViewSrc from "../../../../../static/rich-push/text-html-web-view.png";
import textImgSrc from "../../../../../static/rich-push/text-image.png";
import textURLSrc from "../../../../../static/rich-push/text-url.png";
import textVideoSrc from "../../../../../static/rich-push/text-video.png";
import textWebViewSrc from "../../../../../static/rich-push/text-web-view.png";
import textYoutubeSrc from "../../../../../static/rich-push/text-youtube.png";
import textSrc from "../../../../../static/rich-push/text.png";
import classes from "../CreateAppPush.scss";

const maxLength30 = maxLength(30);

class PushMessageStep extends React.Component {
  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().fadeIn(250);

    const selectedType = this.props.selectedType;

    if (selectedType) {
      this.handleRadioSelection(selectedType);
    }

    if (this.props.push.duplicate) {
      const campaignName = this.props.push.duplicate.name;
      this.props.dispatch(
        change(
          "pushMessageStep",
          "action.campaign_name",
          `${campaignName} copy`
        )
      );
    }

    if (this.props.config.action.appPush.type) {
      this.props.change(
        "pushMessageStep",
        this.props.config.action.appPush.type
      );
      this.props.change(
        "pushMessageStep",
        this.props.config.action.appPush.selectedType
      );
      this.handleRadioSelection(this.props.config.action.appPush.selectedType);
    }
  }

  handleRadioSelected = (id, type) => {
    const { handleChange, config } = this.props;
    if (id.toLowerCase().includes("silent")) {
      this.props.handleSilentPushSequence(true);
    } else {
      this.props.handleSilentPushSequence(false);
    }
    this.props.dispatch(change("pushMessageStep", "type", id));
    this.props.dispatch(
      change("pushMessageStep", "action.appPush.selectedType", type)
    );
    if (config.action.appPush.selectedType) {
      handleChange("action.appPush.selectedType", type);
    } else {
      handleChange("action.appPush.selectedType", id);
    }
  };

  handleRadioSelection = (type) => {
    switch (type) {
      case "text":
        this.handleRadioSelected("Text", "text");
        break;
      case "photo":
        this.handleRadioSelected("Text + Image", "photo");
        break;
      case "url":
        this.handleRadioSelected("Text + URL", "url");
        break;
      case "gif":
        this.handleRadioSelected("Text + GIF", "gif");
        break;
      case "video":
        this.handleRadioSelected("Text + Video", "video");
        break;
      case "webView":
        this.handleRadioSelected("Text + Web view", "webView");
        break;
      case "htmlWebVideo":
        this.handleRadioSelected("Text + Youtube", "htmlWebVideo");
        break;
      case "htmlWebView":
        this.handleRadioSelected("Text + Html web view", "htmlWebView");
        break;
      case "silent":
        this.handleRadioSelected("Silent Push", "silent");
        break;
      default:
        break;
    }
  };
  renderRadioButton = (id, label, key, imgSrc, type) => (
    <div
      onMouseDown={() => {
        this.handleRadioSelected(label, type);
      }}
      key={key}
      className={`${
        this.props.pushMessageStep.values.action.appPush.selectedType === type
          ? classes["hightlight-checked"]
          : ""
      } ${classes["radion-container"]}`}
    >
      <img src={imgSrc} />
    </div>
  );
  renderRadioButtons = () => {
    const labels = [
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_1"),
        src: textSrc,
        type: "text",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_2"),
        src: textImgSrc,
        type: "photo",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_3"),
        src: textURLSrc,
        type: "url",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_4"),
        src: textGifSrc,
        type: "gif",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_5"),
        src: textVideoSrc,
        type: "video",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_6"),
        src: textYoutubeSrc,
        type: "htmlWebVideo",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_7"),
        src: textWebViewSrc,
        type: "webView",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_8"),
        src: silentPushSrc,
        type: "silent",
      },
      {
        label: I18n.t("createAppPush_PushMessageStep_labels_9"),
        src: textHTMLWebViewSrc,
        type: "htmlWebView",
      },
    ];
    return (
      <Row>
        {labels.map((x, key) => {
          const { label, src, type } = x;
          return (
            <Col key={key} xs={4}>
              <Field
                validate={[required]}
                name="type"
                component={() =>
                  this.renderRadioButton(
                    label,
                    label,
                    `${label}${key}`,
                    src,
                    type
                  )
                }
                type="radio"
                /* checked={
                this.props.pushMessageStep.type ||
                this.props.config.action.appPush.type
              } */
              />
            </Col>
          );
        })}
      </Row>
    );
  };
  render() {
    const { handleChange, config } = this.props;
    return (
      <Form>
        <Field
          name={"action.campaign_name"}
          component={TextBox}
          label={I18n.t("CampaignName_label")}
          className={classes["campaign-name-input"]}
          validate={[required, maxLength30, isValidNotEmptySpace]}
          maxLength={30}
          value={config.action.campaign_name}
          onChange={(e, value) => handleChange("action.campaign_name", value)}
          placeholder={I18n.t("CampaignName_placeholder")}
        />
        <div className={classes["container-radios"]}>
          {this.renderRadioButtons()}
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "pushMessageStep",

  destroyOnUnmount: false,
})(
  connect((store) => ({
    push: store.push,
    pushMessageStep: store.form.pushMessageStep,
  }))(PushMessageStep)
);
