import React from "react";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { connect } from "react-redux";
import { getCurrentSuit } from "../../reducers/user";

export const getCustomPages = async (getCurrentSuitFunc, suitId) => {
  try {
    if (!suitId) {
      return [];
    }

    const response = await getCurrentSuitFunc(suitId);

    if (response &&
      response.value &&
      response.value.body &&
      response.value.body.meta &&
      response.value.body.meta.customPages) {
      return response.value.body.meta.customPages;
    }

    return [];
  } catch (error) {
    console.error("Error fetching custom pages:", error);
    return [];
  }
};

export const fetchCustomPages = (component, props) => {
  const { user, getCurrentSuit } = props;

  if (!user || !user.activeSuitId || component.state.loading) {
    return;
  }

  component.setState({ loading: true });

  getCustomPages(getCurrentSuit, user.activeSuitId)
    .then(pages => {
      if (!component._isMounted) return;

      component.setState({
        customPages: pages,
        loading: false
      });
    })
    .catch(error => {
      if (!component._isMounted) return;
      console.error("Error fetching custom pages:", error);
      component.setState({ loading: false });
    });
};

export const getCustomPagesMenuItem = (customPages, subdomain, classes) => {
  if (!customPages || customPages.length === 0) {
    return null;
  }

  const children = customPages.map(page => ({
    key: `custom-page-${page.id}`,
    title: page.name,
    link: `/app/${subdomain}/customPages/${page.id}`,
    url: page.url,
    externalLink: page.externalLink
  }));

  return {
    key: "custom-pages",
    title: "Custom Pages",
    Icon: () => <HiOutlineDocumentDuplicate className={classes} />,
    children
  };
};

export const addCustomPagesToSidebar = (baseItems, customPages, subdomain, iconClass) => {
  if (!customPages || customPages.length === 0) {
    return baseItems;
  }

  const customPagesItem = getCustomPagesMenuItem(customPages, subdomain, iconClass);
  if (customPagesItem) {
    return [...baseItems, customPagesItem];
  }

  return baseItems;
};

export const getCustomPageMenuItems = (customPages) => {
  if (!customPages || customPages.length === 0) {
    return [];
  }

  return customPages.map((page) => ({
    key: page.id,
    title: page.name,
    url: page.url,
    externalLink: false
  }));
};

export const withCustomPages = (WrappedComponent) => {
  class WithCustomPages extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        customPages: [],
        loading: false
      };
      this._isMounted = false;
    }

    componentDidMount() {
      this._isMounted = true;
      this.fetchCustomPagesData();
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
      if (prevProps.user && this.props.user &&
        prevProps.user.activeSuitId !== this.props.user.activeSuitId) {
        this.fetchCustomPagesData();
      }
    }
    fetchCustomPagesData = () => {
      fetchCustomPages(this, this.props);
    }
    render() {
      const { customPages, loading } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          customPages={customPages}
          customPagesLoading={loading}
        />
      );
    }
  }

  WithCustomPages.displayName = `WithCustomPages(${getDisplayName(WrappedComponent)})`;

  return connect(
    (state) => ({
      user: state.user
    }),
    { getCurrentSuit }
  )(WithCustomPages);
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
