/* eslint-disable no-undef */
export default [
  {
    path: "/auth/passrest",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./Auth/ResetPassword").default);
        },
        (err) => {
          console.error("Dynamic page loading failed", err);
        },
        "auth-reset"
      );
    },
    auth: false,
  },
  {
    path: "/app/:subdomain/eventsDefinition",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/EventsDefinition").default);
        },
        "events-Definition"
      );
    },
    auth: true,
  },
  {
    path: "/app/:subdomain/notificationLogs",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Notifylogs/List").default);
        },
        "notificationLogs"
      );
    },
    auth: true,
  },
  {
    path: "/auth/login",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./Auth/Login").default);
        },
        "auth-login"
      );
    },
    auth: true,
  },
  {
    path: "/app/:subdomain/tutorials",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Tutorials").default);
        },
        "app-tuts"
      );
    },
  },
  {
    path: "/app/:subdomain/customPages/:pageId",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/customPages").default);
        },
        "customPages"
      );
    },
  },
  {
    path: "/auth/confirmed",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./Auth/Confirmed").default);
        },
        "auth-confirmed"
      );
    },
    auth: true,
  },
  {
    path: "/auth/forgot",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./Auth/Forgot").default);
        },
        "auth-forgot"
      );
    },
    auth: true,
  },

  {
    path: "/home",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./Home").default);
        },
        "Home"
      );
    },
    private: true,
  },
  {
    path: "/create-new-project",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./CreateFreeProject").default);
        },
        "CreateFreeProject"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/upgrade-plan",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UpgradePlan").default);
        },
        "UpgradePlan"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Summary").default);
        },
        "summary"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/newcampaigns",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/NewCampaigns").default);
        },
        "newcampaigns"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/campaignssummary",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/CampaignsSummary").default);
        },
        "summaryCont"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/settings",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/NewSettings").default);
        },
        "settings"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/ApiAndSdk",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/ApiAndSdk").default);
        },
        "apiandsdk"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/integrations",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/Setting/components/ExternalIntegrations").default
          );
        },
        "integrations"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/CommunicationChannels",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/AppBackeEnd/Settings/component/CommunicationChannels")
              .default
          );
        },
        "CommunicationChannels"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/deeplinking",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/DeepLinking").default);
        },
        "deeplinking"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/subscriptions",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/Subscriptions").default);
        },
        "subscriptions"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/revenue",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Summary/AppBoostRevenue").default);
        },
        "revenu"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/operations",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/Operations").default);
        },
        "operations"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/projectinfo",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/ProjectInfo").default);
        },
        "projectinfo"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/setting/:name",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Setting/components/Edit").default);
        },
        "projectinfo"
      );
    },
    private: true,
  },
  // SubUsers Management
  {
    path: "/user/sub-users",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/SubUsers").default);
        },
        "SubUsers"
      );
    },
    private: true,
  },
  {
    path: "/user/:section",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UserProfile").default);
        },
        "apps-profile-edit"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/mobileusers",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UsersScreen").default);
        },
        "UsersScreen"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/retailSegments",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/RetailGainSegments").default);
        },
        "RetailGainSegments"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/mobileusers/new",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UsersScreen/Create").default);
        },
        "newUsersScreen"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/mobileusers/upload",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UsersScreen/upload").default);
        },
        "uploadnewUsersScreen"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/usertimeline/:userId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/userTimline").default);
        },
        "userTimline"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/mobileUserInfo/:userId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MobileUserInfo").default);
        },
        "mobileUserInfo"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/digitalinvoices",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/DigitalInvoice").default);
        },
        "DigitalInvoice"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/digitalinvoices/new",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/DigitalInvoice/CreateInvoices").default);
        },
        "NewDigitalInvoice"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/automator",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Automator/List").default);
        },
        "automator"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/automator/:automatorId/analytics",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Automator/AutomatorAnalytics").default);
        },
        "analyticsAutomator"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/automator/:automatorId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Automator/Create").default);
        },
        "newAutomator"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/messaginglogs",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Notifylogs/List").default);
        },
        "NotifyLogs"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/template",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Template/List").default);
        },
        "Template List"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/template/:type/:templateId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Template/Create").default);
        },
        "newTemplate"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/template/:type",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Template/Create").default);
        },
        "newTemplate"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smartlinks/:smartlinkId/analytics",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/NewSDLAnalytics").default);
        },
        "smartlinkAnalytics"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smartlinks/:smartlinkId/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/SmartDeepLinks/CreateNewSmartLink").default);
        },
        "editSmartlink"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smartlinks/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/SmartDeepLinks/CreateNewSmartLink").default);
        },
        "newSmartlink"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/platforms/sms",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/Config/SMS").default);
        },
        "SMSConfiguration"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/advancedconfig",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/AppBackeEnd/Settings/component/ConfigAdvanced")
              .default
          );
        },
        "SMSConfiguration"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/emailsettings",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/Config/Email").default);
        },
        "EmailConfiguration"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smartDeepLinks",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/SmartDeepLinks/ListSmartDeepLinks").default);
        },
        "smartDeepLinks"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/:landingPageId/analytics",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MicroPages/MicroPageAnalytics").default);
        },
        "MicroPageAnalytics"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MicroPages/ListMicroPages").default);
        },
        "MicroPages"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MicroPages/CreateMicroPage").default);
        },
        "NewMicroPages"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/:landingPageId/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MicroPages/CreateMicroPage").default);
        },
        "NewMicroPages"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/leadform/:landingPageId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/LeadForm/ListLeadForm").default);
        },
        "leadform"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/stripepay/:landingPageId",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/StripPay/ListStripPay").default);
        },
        "StripPay"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/push/web/subscribers",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/WebPushSubscribers").default);
        },
        "webPushSubscribers"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/push/app/:campaignId/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateAppPush").default);
        },
        "duplicateAppPush"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/push/app/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateAppPush").default);
        },
        "newPush"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/push/web/:campaignId/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateWebPush").default);
        },
        "duplicateWebPush"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/push/web/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateWebPush").default);
        },
        "newPush"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/sms/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateSms/CreateSms").default);
        },
        "newSms"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/whatsapp/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateWhatsApp").default);
        },
        "New Whats App"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/unofficial-whatsapp/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateUnofficialWhatsApp").default);
        },
        "create-unofficial-whatsapp"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/welcome",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/NewBoarding").default);
        },
        "onBoarding"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smstargetlists/upload",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/UploadTargetList").default);
        },
        "targetlist"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smartLinks/upload",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/SmartDeepLinks/UploadBulkSDL").default);
        },
        "upload"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/smstargetlists",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/ListTargetList").default);
        },
        "listtargetlist"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/newpush/:type/:pushId/analytics",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/NewPushAnalytics").default);
        },
        "pushAnalytics"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/push/:type/:pushId/analytics",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/PushAnalytics").default);
        },
        "pushAnalytics"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/mobileAttributes",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/MobileAttributes").default);
        },
        "mobileAttributes"
      );
    },
    private: true,
  },
  // {
  //   path: '/app/:subdomain/appbackend/setting',
  //   getComponent: (nextState, cb) => {
  //     require.ensure(
  //       [],
  //       require => {
  //         cb(null, require('./App/AppBackeEnd/Settings/com').default)
  //       },
  //       'setting',
  //     )
  //   },
  //   private: true,
  // },
  {
    path: "/app/:subdomain/appbackend/cloudFunction",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AppBackeEnd/CloudFunction").default);
        },
        "cloudFunction"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/appbackend/deeplinks",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/AppBackeEnd/Deeplinks/ListDeepLinks").default
          );
        },
        "deeplinks"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/appbackend/deeplinks/upload",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AppBackeEnd/Deeplinks/Upload").default);
        },
        "deeplinks upload"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/appbackend/deeplinks/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/AppBackeEnd/Deeplinks/CreateNewDeepLink").default
          );
        },
        "newdeeplinks"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/email/new",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Push/CreateEmail/CreateEmail").default);
        },
        "newEmail"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/UninstallMonitor",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/UninstallMonitor").default);
        },
        "UninstallMonitor"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/RevenueTransactions",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Revenue").default);
        },
        "Revenue"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/reports",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Reports/index").default);
        },
        "reports"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/CustomerSegments/ListCustomerSegments",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/CustomerSegments/ListCustomerSegments").default
          );
        },
        "CustomerSegments"
      );
    },
    private: true,
  },
  // create new segments
  {
    path: "/app/:subdomain/CustomerSegments/:action",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/CustomerSegments/CreateNewCustomerSegments").default
          );
        },
        "CreateNewCustomerSegments"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/RichUserProfile",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AppUser").default);
        },
        "AppUser"
      );
    },
    private: true,
  },
  // upload App Users
  {
    path: "/app/:subdomain/RichUserProfile/upload",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AppUser/upload").default);
        },
        "UploadAppUser"
      );
    },
    private: true,
  },
  // upload bulk app push
  {
    path: "/app/:subdomain/appPush/upload",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/CampaignsSummary/components/lists/UploadBulkAppPush")
              .default
          );
        },
        "UploadAppPush"
      );
    },
    private: true,
  },
  // upload bulk web push
  {
    path: "/app/:subdomain/webPush/upload",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(
            null,
            require("./App/CampaignsSummary/components/lists/UploadBulkWebPush")
              .default
          );
        },
        "UploadWebPush"
      );
    },
    private: true,
  },
  // Events
  {
    path: "/app/:subdomain/Events",

    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Events").default);
        },
        "Events"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/EventDetails/:eventId",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/EventDetails").default);
        },
        "EventDetails"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/products",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Products").default);
        },
        "Products"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/products/add",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AddProduct").default);
        },
        "Add Product"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/products/edit/:productId",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/AddProduct").default);
        },
        "Edit Product"
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/products/upload",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Products/UploadProducts").default);
        },
        "Upload Products"
      );
    },
    private: true,
  },

  {
    path: "/app/:subdomain/coupons",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Coupons").default);
        },
        "List Coupons"
      );
    },
  },
  {
    path: "/app/:subdomain/coupons/create",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Coupons/CreateCoupon").default);
        },
        "List Coupons"
      );
    },
  },
  {
    path: "/app/:subdomain/points",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Points").default);
        },
        "Points"
      );
    },
  },

  {
    path: "/app/:subdomain/banners",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Banners").default);
        },
        "Banners"
      );
    },
  },

  {
    path: "/app/:subdomain/categories",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./App/Categories").default);
        },
        "Collections"
      );
    },
  },

  {
    path: "*",
    getComponent: (nextState, cb) => {
      require.ensure(
        [],
        (require) => {
          cb(null, require("./NotFound").default);
        },
        "otherwise"
      );
    },
    private: true,
  },
];
