import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import logoMain from "static/logos/logo.png";
import { CHARITY_APP, MOBILE_APP, RETAIL_GAIN } from "../../consts/suitTypes";
import { activeSidebarMenuItem, selectedKeys } from "../../modules/sidebar";
import { getSuitBySubdomain, identifySuitType } from "../../modules/user";
import classes from "./Sidebar.module.scss";
import {
  CHARITY_SIDEBAR_ITEMS,
  MOBILE_APP_SIDEBAR_ITEMS,
  RETAIL_GAIN_SIDEBAR_ITEMS,
} from "./sidebarItems";
import { addCustomPagesToSidebar, withCustomPages } from "./dynamicCustomPages";

const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.pathname
    };
  }

  componentDidMount() {
    this.unlisten = this.props.router.listen((location) => {
      this.setState({
        currentUrl: location.pathname
      });
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { router, subdomain, projectName, onCollapse, collapsed, customPages } = this.props;
    const { currentUrl } = this.state;

    const suit = getSuitBySubdomain(subdomain);
    const suitType = identifySuitType(suit?.plan_summary?.offers_values);
    const url = currentUrl;
    let sidebarItems =
      suitType === MOBILE_APP
        ? MOBILE_APP_SIDEBAR_ITEMS
        : suitType === CHARITY_APP
          ? CHARITY_SIDEBAR_ITEMS
          : suitType === RETAIL_GAIN
            ? RETAIL_GAIN_SIDEBAR_ITEMS
            : [];

    const baseItems = sidebarItems(subdomain, suit?.id);
    const itemsWithCustomPages = addCustomPagesToSidebar(
      baseItems,
      customPages,
      subdomain,
      classes.sidebarItemIcon
    );

    const selectedSidebarItems = selectedKeys(
      itemsWithCustomPages,
      url
    );

    const activeSidebarItem = activeSidebarMenuItem(
      itemsWithCustomPages,
      url
    );

    return (
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={250}
        className={classes.sidebar}
      >
        {!collapsed ? (
          <div className={classes.sidebarTopContainer}>
            <div className={classes.sidebarTopContainerInner}>
              <img
                onClick={() => router.push("/")}
                alt="Appgain"
                src={logoMain}
                width={140}
              />

              <span className={classes.projectName}>{projectName}</span>
            </div>
          </div>
        ) : (
          <div className={classes.placeholderDiv} /> // just to save space
        )}
        <Menu
          theme="dark"
          defaultOpenKeys={selectedSidebarItems}
          selectedKeys={[activeSidebarItem?.key]}
          mode="inline"
        >
          {itemsWithCustomPages.map((item) => {
            if (item?.children) {
              const { Icon, key, title, children } = item;
              return (
                <SubMenu
                  key={key}
                  title={
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: collapsed ? "24px" : "16px",
                      }}
                    >
                      <Icon />
                      <span>{title}</span>
                    </span>
                  }
                >
                  {children.map((child) => {
                    if (child.externalLink) {
                      return (
                        <Menu.Item key={child.key}>
                          <a
                            href={child.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.sidebarSubItemLabel}
                          >
                            {child.title}
                          </a>
                        </Menu.Item>
                      );
                    }
                    else if (child?.children) {
                      return (
                        <SubMenu
                          key={child?.key}
                          title={
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: collapsed ? "24px" : "16px",
                              }}
                            >
                              <span>{child?.title}</span>
                            </span>
                          }
                        >
                          {child.children.map((grandChild) => {
                            return (
                              <Menu.Item key={grandChild?.key}>
                                <Link to={grandChild?.link}>
                                  <span>{grandChild?.title}</span>
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }
                    else {
                      const { key, title, link } = child;
                      return (
                        <Menu.Item key={key}>
                          <Link to={link}>
                            <span>{title}</span>
                          </Link>
                        </Menu.Item>
                      );
                    }
                  })}
                </SubMenu>
              );
            } else {
              const { Icon, key, title, link, disabled } = item;
              return (
                <Menu.Item key={key} disabled={disabled}>
                  <Link
                    to={link}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: collapsed ? "24px" : "16px",
                    }}
                  >
                    <Icon />
                    <span>{title}</span>
                  </Link>
                </Menu.Item>
              );
            }
          })}
        </Menu>
      </Sider>
    );
  }
}

Sidebar.propTypes = {
  router: PropTypes.object.isRequired,
  subdomain: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  onCollapse: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  customPages: PropTypes.array,
  customPagesLoading: PropTypes.bool
};

export default withCustomPages(Sidebar);
