import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { getNotifyConfig } from "reducers/config";
import UnofficialWhatsAppAPI from "../../../../../api/UnofficialWhatsAppAPI";
import { PrimaryButton, SpinLoader } from "../../../../../components";

const mapStateToProps = (state) => ({
  notify: state.config.notify || {},
  user: state.user,
  isAuthenticated: state.config.notify?.isAuthenticated ?? false,
});

class Initialize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: "",
      isAuthenticated: false,
      configData: null,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const configData = await this.props.getNotifyConfig(
        this.props.user.activeSuitId
      );
      const isAuthenticated =
        configData.value.body.channels["uo-whatsapp"].isAuthenticated;

      this.setState({ isAuthenticated, configData, loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async handleInitialize(suitId) {
    this.setState({ loading: true });
    try {
      const response = await UnofficialWhatsAppAPI.InitializeApi(suitId);
      const url = response.body.url;
      this.setState({ url });
    } catch (error) {
      console.error("Error initializing API:", error);
      this.setState({ url: "" });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleNextClick = () => {
    this.props.history.replace(
      `/app/${window.location.href.split("/")[4]}/newcampaigns`
    );
  };

  render() {
    const { url, isAuthenticated, loading, configData } = this.state;
    return (
      <div style={{ fontSize: "20px", margin: "20px" }}>
        {loading ? (
          <SpinLoader />
        ) : isAuthenticated ? (
          <div>
            <p>You are authenticated, go to send the message.</p>
            <a
              href={configData.value.body.channels["uo-whatsapp"].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp Link
            </a>
          </div>
        ) : url ? (
          <a
            href={`${url}/whatsapp-login`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to authenticate your number
          </a>
        ) : (
          <div>
            <p>You are not authenticated.</p>
            <PrimaryButton
              type="button"
              onClick={() =>
                this.handleInitialize(this.props.user.activeSuitId)
              }
            >
              Initialize
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  }
}

Initialize.propTypes = {
  user: PropTypes.shape({
    activeSuitId: PropTypes.string.isRequired,
  }).isRequired,
  getNotifyConfig: PropTypes.func.isRequired,
  notify: PropTypes.object,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, { getNotifyConfig })(
  reduxForm({
    form: "initializeStep",
    destroyOnUnmount: false,
  })(Initialize)
);
